<template>
  <div class="page-detail">
    <Page>
        <div class="container mx-auto bg-neutral-100 p-4 rounded-lg">
            <h1 id="title">{{ page.title }}</h1>
            <div v-html="htmlBody"></div>
        </div>
    </Page>
  </div>
</template>
<script>
import { marked } from 'marked'

import Page from '@/components/page.vue'

export default {
  name: 'PageDetail',
  components: {
    Page
  },
  computed: {
    /*
    Covert markdown page.body into html for rendering on the page
    */
    htmlBody: function () {
      return marked(this.page.body)
    },
    /*
    Grab the correct `Page` entry from the store and save to `page` variable
    */
    page: function () {
      return this.$store.state.pages.find(page => page.slug === this.$route.params.slug)
    }
  }
}
</script>
